<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
          class="c-input-xs"
          v-model="code"
          type="text"
          :label="$t('labels.detail_tracking_code')"
          dense
          outlined
          clearable
          hide-details
          @keyup.enter="getOrder"
        ></v-text-field>
      </v-col>

      <template v-if="order && order.id">
        <v-col cols="12" style="height: calc(100vh - 185px); overflow: auto">
          <v-row>
            <v-col md="4" cols="12">
              <v-simple-table dense class="table-bordered">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <th
                        role="columnheader"
                        class="text-center"
                        style="width: 30%"
                      >
                        {{ order.warehouse_code }}
                      </th>
                      <th
                        role="columnheader"
                        class="text-center"
                        style="width: 30%"
                      >
                        {{ order.delivery_short_name }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ order.company_name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-center">
                      <td>{{ order.tracking_id }}</td>
                      <td>
                        <div class="d-flex align-center justify-center">
                          <DeliveryTracking :order="order" />
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-center justify-center">
                          {{ order.customer_order_id }}
                        </div>
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td class="error--text font-weight-medium">
                        {{ order.state_name }}
                        <div v-if="order.request_cancel" class="mt-1">
                          <v-chip color="red" text-color="white" small>{{
                            $t("labels.cancelled")
                          }}</v-chip>
                        </div>
                      </td>
                      <td>{{ order.delivery_partner_state }}</td>
                      <td class="error--text font-weight-medium">
                        {{ order.delivery_state_name }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col md="8" cols="12">
              <v-simple-table dense class="table-bordered">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.name") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.phone_number") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.city") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.district") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.ward") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.address") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.cod") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-center">
                      <td>{{ replaceTxtStar(order.receiver_name) }}</td>
                      <td>{{ replaceTxtStar(order.receiver_phone_number) }}</td>
                      <td class="text-capitalize">{{ order.city_name }}</td>
                      <td class="text-capitalize">{{ order.county_name }}</td>
                      <td class="text-capitalize">{{ order.ward_name }}</td>
                      <td class="text-capitalize" style="width: 20%">
                        {{ replaceTxtStar(order.address) }}
                      </td>
                      <td>{{ formatNumber(order.final_cod) }}</td>
                    </tr>
                    <tr v-if="canViewBill">
                      <td colspan="7">
                        {{ $t("labels.order_prepay") }}:
                        <span
                          class="primary--text cursor-pointer text-decoration-underline font-weight-medium"
                          @click="showBillImages"
                          >{{ formatNumber(order.prepay) }} </span
                        >. {{ $t("labels.order_bank_account") }}:
                        <span class="font-weight-medium">
                          {{ order.bank_account }}</span
                        >
                        -
                        {{ order.bank_name }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>

            <v-col cols="12" md="4">
              <v-simple-table dense class="table-bordered">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <th
                        role="columnheader"
                        class="text-center"
                        style="width: 30%"
                      >
                        {{ $t("labels.create_method") }}
                      </th>
                      <th
                        role="columnheader"
                        class="text-center"
                        style="width: 30%"
                      >
                        {{ $t("labels.e_market") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.shop") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-center">
                      <td>
                        {{ $t(`labels.create_method_${order.create_method}`) }}
                      </td>
                      <td>{{ order.e_marketplace_short_name }}</td>
                      <td>{{ order.customer_e_marketplace_shop_name }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="8">
              <v-simple-table dense class="table-bordered">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.number_of_goods") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.quantity") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.total_pre_weight") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.total_weight") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.cod_1") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.note") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.cod_2") }}
                      </th>
                      <th
                        role="columnheader"
                        class="text-center"
                        v-if="order.indemnify_amount"
                      >
                        {{ $t("labels.indemnify") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-center">
                      <td>{{ items.length }}</td>
                      <td>{{ itemSum.sumItem }}</td>
                      <td>{{ formatNumber(itemSum.sumPreWeight) }}g</td>
                      <td>{{ formatNumber(itemSum.sumWeight) }}g</td>
                      <td>{{ formatNumber(order.cash_on_delivery) }}</td>
                      <td>{{ order.note }}</td>
                      <td>
                        {{
                          order.indemnify_amount
                            ? formatNumber(order.indemnify_amount)
                            : formatNumber(order.money_collected)
                        }}
                      </td>
                      <td class="warning--text" v-if="order.indemnify_amount">
                        {{ formatNumber(order.indemnify_amount) }} ({{
                          order.indemnify_note
                        }})
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>

            <v-col cols="12" md="4">
              <v-simple-table dense class="table-bordered">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <th
                        role="columnheader"
                        class="text-right"
                        style="width: 30%"
                      >
                        {{ $t("labels.status") }}
                      </th>
                      <th
                        role="columnheader"
                        class="text-left"
                        style="width: 70%"
                      >
                        {{ $t("labels.time") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-right">
                        {{ $t("labels.create_order_1") }}
                      </td>
                      <td class="text-left">
                        {{
                          formatDateTime(
                            order.create_time,
                            "HH:mm:ss DD/MM/YYYY"
                          )
                        }}
                        <br />
                        <span class="font-italic error--text">
                          {{ order.create_by_name }}
                        </span>
                      </td>
                    </tr>
                    <tr v-if="order.change_item_by_name">
                      <td class="text-right">
                        {{ $t("labels.employee_change_item") }}
                      </td>
                      <td class="text-left font-italic error--text">
                        {{ order.change_item_by_name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right">
                        {{ $t("labels.order_status_40") }}
                      </td>
                      <td class="text-left">
                        {{
                          order.receipt_at
                            ? formatDateTime(
                                order.receipt_at,
                                "HH:mm:ss DD/MM/YYYY"
                              )
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right">
                        {{ $t("labels.order_stamp_create") }}
                      </td>
                      <td class="text-left">
                        {{
                          order.stamp_created_at
                            ? formatDateTime(
                                order.stamp_created_at,
                                "HH:mm:ss DD/MM/YYYY"
                              )
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr v-if="order.prevent_pickup_created_at">
                      <td class="text-right">
                        {{ $t("labels.prevent_pickup") }}
                      </td>
                      <td>
                        {{
                          order.prevent_pickup_created_at
                            ? formatDateTime(
                                order.prevent_pickup_created_at,
                                "HH:mm:ss DD/MM/YYYY"
                              )
                            : ""
                        }}
                        -
                        {{
                          order.prevent_pickup_expired_at
                            ? formatDateTime(
                                order.prevent_pickup_expired_at,
                                "HH:mm:ss DD/MM/YYYY"
                              )
                            : ""
                        }}
                        <br />
                        <span class="error--text font-italic">
                          {{ order.prevent_pickup_by_name }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right">
                        {{ $t("labels.order_status_60") }}
                      </td>
                      <td class="text-left">
                        {{
                          order.handling_at
                            ? formatDateTime(
                                order.handling_at,
                                "HH:mm:ss DD/MM/YYYY"
                              )
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right">
                        {{ $t("labels.order_status_100") }}
                      </td>
                      <td class="text-left">
                        {{
                          order.pickup_at
                            ? formatDateTime(
                                order.pickup_at,
                                "HH:mm:ss DD/MM/YYYY"
                              )
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right">
                        {{ $t("labels.order_packing_scanned") }}
                      </td>
                      <td class="text-left">
                        {{
                          order.start_packing_at
                            ? formatDateTime(
                                order.start_packing_at,
                                "HH:mm:ss DD/MM/YYYY"
                              )
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right">
                        {{ $t("labels.order_packing_printed") }}
                      </td>
                      <td class="text-left">
                        {{
                          order.start_packing_print_stamp_at
                            ? formatDateTime(
                                order.start_packing_print_stamp_at,
                                "HH:mm:ss DD/MM/YYYY"
                              )
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right">
                        {{ $t("labels.order_status_160") }}
                      </td>
                      <td class="text-left">
                        {{
                          order.package_at
                            ? formatDateTime(
                                order.package_at,
                                "HH:mm:ss DD/MM/YYYY"
                              )
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right">
                        {{ $t("labels.order_packing_table") }}
                      </td>
                      <td class="text-left">
                        {{ order.packing_table_code || "" }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right">
                        {{ $t("labels.order_handover_scanned") }}
                      </td>
                      <td class="text-left">
                        {{
                          order.order_handover_scan_at
                            ? formatDateTime(
                                order.order_handover_scan_at,
                                "HH:mm:ss DD/MM/YYYY"
                              )
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right">
                        {{ $t("labels.order_status_200") }}
                        <template v-if="handover.id">
                          <br />
                          <HandoverImage :item="handover" />
                          <v-btn
                            class="ml-1"
                            x-small
                            color="success"
                            outlined
                            @click="downloadHandoverFile(handover)"
                          >
                            <img
                              src="@/assets/common/download.png"
                              style="height: 16px"
                              alt=""
                            />
                          </v-btn>
                        </template>
                      </td>
                      <td class="text-left">
                        {{
                          order.hand_over_at
                            ? formatDateTime(
                                order.hand_over_at,
                                "HH:mm:ss DD/MM/YYYY"
                              )
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right">
                        {{ $t("labels.order_status_220") }}
                      </td>
                      <td class="text-left">
                        {{
                          order.confirm_return_at
                            ? formatDateTime(
                                order.confirm_return_at,
                                "HH:mm:ss DD/MM/YYYY"
                              )
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr v-if="order.goods_return_note">
                      <td colspan="2">
                        <span
                          v-if="order.goods_return_seal_status === 0"
                          class="error--text"
                          >{{ $t("labels.lost_seal") }} -
                        </span>
                        <span
                          v-if="order.goods_return_is_wet_or_damaged === 1"
                          class="error--text"
                        >
                          {{ $t("labels.is_damage") }} -
                        </span>
                        <span class="warning--text">
                          {{ order.goods_return_note }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right">
                        {{ $t("labels.order_status_240") }}
                      </td>
                      <td class="text-left">
                        {{
                          order.receipt_return_at
                            ? formatDateTime(
                                order.receipt_return_at,
                                "HH:mm:ss DD/MM/YYYY"
                              )
                            : ""
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="8">
              <v-simple-table dense class="table-bordered">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.sku") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.barcode") }}
                      </th>
                      <th
                        role="columnheader"
                        class="text-center"
                        style="width: 25%"
                      >
                        {{ $t("labels.goods") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.goods_description") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.size") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.export") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.return_quantity") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.return_note") }}
                      </th>
                      <template
                        v-if="
                          checkPermission([
                            'customer_account',
                            'order_detail_price',
                          ]) && checkModule(['warehouse_order_detail_price'])
                        "
                      >
                        <th role="columnheader" class="text-center">
                          {{ $t("labels.price_type") }}
                        </th>
                        <th role="columnheader" class="text-center">
                          {{ $t("labels.base_price") }}
                        </th>
                        <th role="columnheader" class="text-center">
                          {{ $t("labels.discount_price_1") }}
                        </th>
                      </template>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="text-center"
                      :class="{
                        'text-decoration-line-through grey--text':
                          item.soft_delete,
                      }"
                      v-for="(item, key) in items"
                      :key="`goods_${item.id_goods}_${key}`"
                    >
                      <td>{{ item.sku }}</td>
                      <td><GoodsCode :code="item.customer_goods_barcode" /></td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ item.size }}</td>
                      <td>{{ formatNumber(item.sum_quantity) }}</td>
                      <td>
                        {{
                          order.state === 240
                            ? formatNumber(item.return_quantity)
                            : ""
                        }}
                      </td>
                      <td>{{ item.return_note }}</td>
                      <template
                        v-if="
                          checkPermission([
                            'customer_account',
                            'order_detail_price',
                          ]) && checkModule(['warehouse_order_detail_price'])
                        "
                      >
                        <td>
                          {{
                            item.price_type
                              ? $t(`labels.${item.price_type}`)
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            item.base_price ? formatNumber(item.base_price) : ""
                          }}
                          <br />
                          <small v-if="item.base_price" class="error--text">
                            ({{
                              formatNumber(item.base_price / item.sum_quantity)
                            }}/sp)
                          </small>
                        </td>
                        <td>
                          {{
                            item.bill_price &&
                            item.base_price != item.bill_price
                              ? formatNumber(item.bill_price)
                              : ""
                          }}
                          <br />
                          <small
                            v-if="
                              item.bill_price &&
                              item.base_price != item.bill_price
                            "
                            class="error--text"
                          >
                            ({{
                              formatNumber(item.bill_price / item.sum_quantity)
                            }}/sp)
                          </small>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col v-if="isOrderDeliveryApi" cols="6" md="3" lg="2">
              <v-btn
                color="success"
                small
                block
                @click="showLogDialog('delivery-status')"
                >{{ $t("labels.log_delivery_status") }}</v-btn
              >
            </v-col>
            <v-col cols="6" md="3" lg="2">
              <v-btn
                color="success"
                small
                block
                @click="showLogDialog('package')"
              >
                {{ $t("labels.log_package") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-row>

    <v-dialog v-model="dialogSelectOrder" persistent max-width="900px">
      <SelectOrderForDetail
        v-if="dialogSelectOrder && orders.length > 0"
        :orders="orders"
        @cancel="hideSelectOrderDialog"
        @selectOrder="selectOrder"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogLog"
      :max-width="
        ['package', 'cost'].includes(dialogLogType) ? '600px' : '1200px'
      "
      v-if="order && order.id"
    >
      <LogOrderDeliveryStatus
        :id-order="order.id"
        v-if="dialogLog && dialogLogType === 'delivery-status'"
        @cancel="hideLogDialog"
      />
      <LogOrderPackage
        :id-order="order.id"
        v-if="dialogLog && dialogLogType === 'package'"
        @cancel="hideLogDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { api as viewerApi } from "v-viewer";

export default {
  name: "Detail",
  components: {
    LogOrderPackage: () => import("@/components/orders/LogOrderPackage"),
    LogOrderDeliveryStatus: () =>
      import("@/components/orders/LogOrderDeliveryStatus"),
    SelectOrderForDetail: () =>
      import("@/components/orders/SelectOrderForDetail"),
    DeliveryTracking: () => import("@/components/common/DeliveryTracking"),
    HandoverImage: () => import("@/components/orders/HandoverImage"),
    GoodsCode: () => import("@/components/common/GoodsCode"),
  },
  data: () => ({
    isLoading: false,
    code: null,
    id: null,
    order: {},
    orders: [],
    items: [],
    dialogUpdateDelivery: false,
    dialogSelectOrder: false,
    dialogLog: false,
    dialogLogType: null,
    dialogIndemnify: false,
    showSameTrackingAll: false,
    handover: {},
  }),
  computed: {
    canViewBill() {
      if (!this.order || !this.order.id) {
        return false;
      }
      const { me } = window;
      if (!this.order.bill_images || this.order.bill_images.length === 0) {
        return false;
      }
      if (
        me.identity_id !== this.order.id_identity_create &&
        !this.checkPermission(["customer_account"])
      ) {
        return false;
      }
      return true;
    },
    isOrderDeliveryApi() {
      if (!this.order || !this.order.id) {
        return false;
      }
      return !!(
        [1, 2, 5].includes(this.order.create_method) &&
        this.order.id_delivery_contract
      );
    },
    itemSum() {
      let sumWeight = 0;
      let sumPreWeight = 0;
      let sumItem = 0;
      if (!this.items || this.items.length === 0) {
        return {
          sumWeight,
          sumPreWeight,
          sumItem,
        };
      }
      this.items.forEach((item) => {
        if (!item.soft_delete) {
          sumWeight += +item.total_weight;
          sumPreWeight += +item.total_pre_weight;
          sumItem += +item.sum_quantity;
        }
      });
      return {
        sumWeight,
        sumPreWeight,
        sumItem,
      };
    },
  },
  mounted() {
    const id = this.getUrlParameter("id");
    if (id) {
      this.id = id;
    }
    const code = this.getUrlParameter("tracking_id");
    if (code) {
      this.code = code;
    }
    if (this.id || this.code) {
      this.getOrder();
    }
  },
  methods: {
    refreshData(id) {
      this.id = id;
      this.getOrder();
    },
    showBillImages() {
      const index = 0;
      const imageUrls = [...this.order.bill_images].map(
        (img) => `${process.env.VUE_APP_FILE_CDN_URL}/${img}`
      );
      viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index,
        },
        images: imageUrls,
      });
    },
    async getOrder() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      this.order = {};
      this.handover = {};
      this.orders = [];
      this.items = [];

      try {
        const { data } = await httpClient.post("/get-order-detail-by-code", {
          code: this.code,
          id: this.id,
        });
        if (!data || data.length === 0) {
          this.$vToastify.error(
            this.$t("messages.tracking_not_found", { tracking: this.code })
          );
          this.isLoading = false;
          this.code = null;
          this.id = null;
          this.$root.$emit("playErrorAudio");
          return false;
        }
        this.orders = [...data];
        if (data.length === 1) {
          await this.selectOrder(data[0].id);
        } else {
          this.showSelectOrderDialog();
        }
        this.isLoading = false;
        this.code = null;
        this.id = null;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.code = null;
        this.id = null;
      }
    },
    async selectOrder(id) {
      const order = [...this.orders].find((i) => i.id === id);
      if (order.tracking_same_phone) {
        order.tracking_same_phone = order.tracking_same_phone.split(";");
      }
      this.order = { ...order };

      const dataItems = await httpClient.post("/get-order-items", {
        id_order: this.order.id,
      });
      this.items = [...(dataItems.data || [])];

      if (
        this.order.id_goods_issue_handing_over &&
        this.checkPermission([
          "customer_account",
          "order_download_handover_file",
        ]) &&
        this.checkModule(["warehouse_order_handover_file"])
      ) {
        const { data } = await httpClient.post("/get-order-handover", {
          id: this.order.id_goods_issue_handing_over,
        });
        this.handover = { ...data };
      }
    },

    showLogDialog(type) {
      this.dialogLog = true;
      this.dialogLogType = type;
    },
    hideLogDialog() {
      this.dialogLog = false;
      this.dialogLogType = null;
    },

    showSelectOrderDialog() {
      this.dialogSelectOrder = true;
    },
    hideSelectOrderDialog() {
      this.dialogSelectOrder = false;
    },
    async downloadHandoverFile() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = `bien-ban-ban-giao-${this.order.tracking_id}.xlsx`;

      try {
        await this.downloadExcelFile(
          "/download-handover-file",
          { id: this.order.id },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
